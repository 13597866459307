//Libraries
import React from "react";
import { Row, Col } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import _ from "lodash";

//Components
import SearchFilter from "../CustomUIComponents/SearchFilter/SearchFilter";
import CustomCard from "../CustomUIComponents/CustomCard/CustomCard";
import MotorcycleCatalogueModalContainer from "../MotorcycleCatalogueModal/MotorcycleCatalogueModalContainer";
import CategoryModal from "../ProductCatalog/CategoryModal";
import Pagination from "../CustomUIComponents/Pagination/Pagination";

//Constants
import {
  SEARCH_FILTER_TYPE,
  FILE_CONFIGURATIONS,
  MOTORCYCLE_FILE_SIZE,
} from "../../constants/appConstants";

import {
  STATUS_SELECTION,
  DEFAULT_CATEGORY_SELECTION,
} from "./Constants";

import {
  DEFAULT_PAGINATION_RECORDS_COUNT,
  DEFAULT_PAGINATION_ACTIVE_PAGE,
} from "../CustomUIComponents/Pagination/PaginationConstants";

import { Strings } from "../../resources/i18n/i18n";

//Utility
import Utility from "../../utils/Utility";

class MotorcycleCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mn: "",
      errorMessage: "",
      category: "",
      model: "",
      status: "",
      cost: "",
      photo: "",
      desc: "",
      motorcycleData:
        props.motorcycleData.length > 0 ? props.motorcycleData : [],
      categories: props.categories.length > 0 ? props.categories : [],
      product: null,
      categoryObject: null,
      recordsPerPage: DEFAULT_PAGINATION_RECORDS_COUNT,
      activePage: DEFAULT_PAGINATION_ACTIVE_PAGE,
      openCategoryModal: false,
      editMotorcycleCatalogue: false,
      selectedMotorcycleCatalogue: {
        id: null,
        mn: "",
        cat: {
          category: "",
        },
        co: "",
        mo: "",
        img: "",
        dc: "",
        bid: "",
      },
      branch: "",
      branches: props.branches?.branches,
    };
  }

  componentDidMount() {
    this.props.getBranchList();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.branches, this.props.branches)) {
      this.setState({ branches: this.props.branches });
    }
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories });
    }
    if (prevProps.motorcycleData !== this.props.motorcycleData) {
      this.setState({ motorcycleData: this.props.motorcycleData });
    }
  }

  closeModal = () => {
    this.setState({
      editMotorcycleCatalogue: false,
      errorMessage: "",
      selectedMotorcycleCatalogue: null,
    });
  };

  onMotorcycleCatalogueClick = (selectedMotorcycleCatalogue) => {
    this.setState({
      editMotorcycleCatalogue: true,
      selectedMotorcycleCatalogue,
    });
  };

  onClickAddMotorCycleButton = () => {
    this.setState({
      editMotorcycleCatalogue: true,
      selectedMotorcycleCatalogue: {
        id: null,
        mn: "",
        cat: {
          category: "",
        },
        co: "",
        mo: "",
        img: "",
        dc: "",
        bid: [],
      },
    });
  };

  onPageChange = ({ page, pageLength }) => {
    this.setState(
      {
        activePage: page,
        recordsPerPage: pageLength,
      },
      () => this.handleOnClickSearchFilter(false)
    );
  };

  handleFiltersChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  resetSearchValues = () => {
    this.setState(
      {
        category: "",
        model: "",
        status: "",
        branch: "",
        errorMessage: "",
      },
      () => {
        this.handleOnClickSearchFilter(true);
      }
    );
  };

  handleOnClickSearchFilter = (isDeFaultOffset) => {
    isDeFaultOffset &&
      this.setState({ activePage: DEFAULT_PAGINATION_ACTIVE_PAGE });

    this.props.handleSearchFilter({
      search: {
        model: this.state.model !== "" ? this.state.model : undefined,
        category:
          this.state.category !== "" &&
            this.state.category !== "Categoría"
            ? this.state.category
            : undefined,
        status:
          this.state.status !== "" &&
            this.state.status !== Strings("Status")
            ? this.state.status
            : undefined,
        branchId:
          this.state.branch !== "" &&
            this.state.branch !== "selectBranch" //we are getting this dropdown values from backend and we are removing strings() because default value of this dropdown is in english.so no need to add spanish string check.
            ? this.state.branch
            : undefined,
      },

      limit: this.state.recordsPerPage,
      offset: isDeFaultOffset
        ? DEFAULT_PAGINATION_ACTIVE_PAGE - 1
        : this.state.activePage - 1,
    });
  };

  renderSearchFilter = () => {
    let filterCategories = this.state.categories;
    if (
      filterCategories.length > 0 &&
      filterCategories[0].id !== ""
    ) {
      filterCategories.unshift(DEFAULT_CATEGORY_SELECTION);
    }
    filterCategories =
      filterCategories.length > 0 &&
      filterCategories.map((category) => {
        return {
          label: category.cat,
          value: Number(category.id),
        };
      });
    let catalogFilters = [
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.category,
        inputType: SEARCH_FILTER_TYPE.NUMBER,
        filterDropdownList: filterCategories,
        key: "category",
      },
      {
        type: SEARCH_FILTER_TYPE.INPUT,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.model,
        key: "model",
        searchPlaceHolder: Strings("model"),
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.status,
        filterDropdownList: [...STATUS_SELECTION],
        key: "status",
      },
      {
        type: SEARCH_FILTER_TYPE.SELECTION,
        handleOnChange: this.handleFiltersChange,
        Value: this.state.branch,
        filterDropdownList: this.props.branches,
        key: "branch",
      },
    ];
    return (
      <Row className='filters'>
        <SearchFilter
          filters={catalogFilters}
          handleOnClickSearchFilter={this.handleOnClickSearchFilter}
          handleOnClickResetFilter={this.resetSearchValues}
          addButton={!this.props.readOnly}
          addAnotherButton={!this.props.readOnly}
          buttonText={Strings("Category")}
          anotherButtonText={Strings("Motorcycle")}
          AnotherButtonClick={this.onClickAddMotorCycleButton}
          handleAddUser={this.toggleCategoryModal} //handle click on add/edit category button
          spaceBetween={false}
          spaceInmd={1}
          paddingLeft={2}
          buttonWidth={" mx-2"}
          anotherButtonWidth={" mt-2"}
        />
      </Row>
    );
  };

  handleChange = (key, event) => {
    if (key === "category") {
      let findIndex = this.state.categories.findIndex(
        (category) => category.cat === event.target.value
      );

      this.setState({
        selectedMotorcycleCatalogue: {
          ...this.state.selectedMotorcycleCatalogue,
          cat: this.state.categories[findIndex],
        },
        errorMessage: "",
      });
    } else if (key === "co") {
      let value =
        event.target.value !== "" ? parseInt(event.target.value) : 0;
      this.setState({
        selectedMotorcycleCatalogue: {
          ...this.state.selectedMotorcycleCatalogue,
          co: value,
        },
        errorMessage: "",
      });
    } else {
      this.setState({
        selectedMotorcycleCatalogue: {
          ...this.state.selectedMotorcycleCatalogue,
          [key]: event.target.value,
        },
        errorMessage: "",
      });
    }
  };

  handleUploadPhoto = (event) => {
    let refThis = this;
    let photo = event.target.files[0];

    if (photo) {
      let fileExtension = Utility.getFileExtension(photo);
      let validFile = true;
      if (
        !_.includes(
          FILE_CONFIGURATIONS.allowedImageExtensions,
          fileExtension
        )
      ) {
        validFile = false;
        this.setState({
          errorMessage: Strings("fileExtension"),
        });
      }
      if (validFile && photo.size > MOTORCYCLE_FILE_SIZE) {
        validFile = false;

        this.setState({
          errorMessage: Strings("sizeIsTooLarge"),
        });
      }
      if (validFile) {
        try {
          Resizer.imageFileResizer(
            event.target.files[0],
            400,
            400,
            fileExtension,
            50,
            0,
            (uri) => {
              try {
                let reader = new FileReader();
                reader.onloadend = () => {
                  refThis.setState({
                    avatar: reader.result,
                  });
                };
                reader.readAsDataURL(uri);
                this.setState({
                  selectedMotorcycleCatalogue: {
                    ...this.state.selectedMotorcycleCatalogue,
                    img: uri,
                  },
                  errorMessage: "",
                });
              } catch (err) {
                this.setState({
                  errorMessage: Strings("imageUploadFailed"),
                });
              }
            },
            "file"
          );
        } catch (err) {
          this.setState({
            errorMessage: Strings("imageUploadFailed"),
          });
        }
      }
    }
  };

  removeImage = () => {
    this.setState({
      avatar: null,
      selectedMotorcycleCatalogue: {
        ...this.state.selectedMotorcycleCatalogue,
        img: null,
      },
    });
  };

  checkError = (reqObj) => {
    if (reqObj.cost === 0) {
      this.setState({
        errorMessage: Strings("costInvalid"),
      });
    } else if (
      reqObj.motorcycleName.length === 0 ||
      reqObj.category === "" ||
      reqObj.category === Strings("category") ||
      reqObj.model === 0 ||
      reqObj.model === "" ||
      reqObj.model === null ||
      reqObj.cost === "" ||
      reqObj.cost === null ||
      reqObj.status === "" ||
      reqObj.status === Strings("Status") ||
      reqObj.file === null ||
      reqObj.file === "" ||
      reqObj.branchIds.length === 0
    ) {
      this.setState({
        errorMessage: Strings("EmptyFieldsError"),
      });
      return false;
    } else {
      this.setState({
        errorMessage: "",
      });
      return true;
    }
  };

  handleSaveMotorcycleClick = () => {
    const { selectedMotorcycleCatalogue } = this.state;
    let reqObj = {
      category: selectedMotorcycleCatalogue.cat.id
        ? selectedMotorcycleCatalogue.cat.id
        : "",
      motorcycleName: selectedMotorcycleCatalogue.mn
        ? selectedMotorcycleCatalogue.mn.trim()
        : "",
      model: selectedMotorcycleCatalogue.mo
        ? selectedMotorcycleCatalogue.mo.trim()
        : "",
      cost: selectedMotorcycleCatalogue.co,
      status: selectedMotorcycleCatalogue.st
        ? selectedMotorcycleCatalogue.st
        : "",
      file: selectedMotorcycleCatalogue.img,
      description: selectedMotorcycleCatalogue.dc
        ? selectedMotorcycleCatalogue.dc.trim()
        : "",
      branchIds: selectedMotorcycleCatalogue.bid
        ? selectedMotorcycleCatalogue.bid.filter(
          (branch) => branch !== "selectBranch"
        )
        : [],
    };
    if (this.checkError(reqObj)) {
      if (
        selectedMotorcycleCatalogue.id === null ||
        selectedMotorcycleCatalogue.id === undefined
      ) {
        this.props.handleAddNewMotorcycle(reqObj, () => {
          this.closeModal();
        });
      } else {
        if (
          Object.prototype.toString.call(reqObj.file) ===
          "[object String]"
        ) {
          delete reqObj.file;
        }
        this.props.handleEditMotorcycle(
          reqObj,
          selectedMotorcycleCatalogue.id,
          () => {
            this.closeModal();
          }
        );
      }
    }
  };

  renderMotorcycleCatalogModal = () => {
    let filterCategories = this.state.categories;
    if (
      filterCategories.length > 0 &&
      filterCategories[0].id !== ""
    ) {
      filterCategories.unshift(DEFAULT_CATEGORY_SELECTION);
    }
    filterCategories =
      filterCategories.length > 0 &&
      filterCategories.map((category) => {
        return {
          label: category.cat,
          value: category.cat,
        };
      });
    let headerText = "";
    if (
      this.state.editMotorcycleCatalogue &&
      this.state.selectedMotorcycleCatalogue.id
    ) {
      if (this.props.readOnly) {
        headerText = Strings("MotorcycleDetails");
      } else {
        headerText = Strings("EditMotorcycleDetails");
      }
    } else {
      headerText = Strings("AddMotorcycleDetails");
    }
    return (
      this.props.branches && (
        <MotorcycleCatalogueModalContainer
          id={this.state.selectedMotorcycleCatalogue.id}
          category={
            this.state.selectedMotorcycleCatalogue.cat.category
          }
          name={this.state.selectedMotorcycleCatalogue.mn}
          model={this.state.selectedMotorcycleCatalogue.mo}
          status={this.state.selectedMotorcycleCatalogue.st}
          cost={this.state.selectedMotorcycleCatalogue.co}
          photo={this.state.selectedMotorcycleCatalogue.img}
          desc={this.state.selectedMotorcycleCatalogue.dc}
          bid={this.state.selectedMotorcycleCatalogue.bid}
          avatar={this.state.avatar}
          closeModal={this.closeModal}
          readOnly={this.props.readOnly}
          headerText={headerText}
          categoryList={filterCategories}
          statusList={STATUS_SELECTION}
          handleChange={this.handleChange}
          handleUploadPhoto={this.handleUploadPhoto}
          handleSaveClick={this.handleSaveMotorcycleClick}
          displayImg={this.state.displayImg}
          errorMessage={this.state.errorMessage}
          removeImage={this.removeImage}
          branches={this.props.branches}
          MOTORCYCLE={true}
        />
      )
    );
  };

  toggleCategoryModal = () => {
    this.setState({
      openCategoryModal: !this.state.openCategoryModal,
      errorMessage: "",
    });
  };

  renderCategoryModal = () => {
    return (
      <CategoryModal
        open={this.state.openCategoryModal}
        modalCloseOnEsc={false}
        modalCloseOnOverlayClick={false}
        modalShowCloseIcon={false}
        headerText={Strings("CategoryModal")}
        modalButtonCancel={{
          text: Strings("Cancel"),
          onClick: this.toggleCategoryModal,
        }}
        modalButtonApprove={{
          text: Strings("Add"),
          onClick: this.handleCategoryRequest,
        }}
        handleChange={this.handleCategoryChange}
        categories={this.state.categories}
        errorMessage={this.state.errorMessage}
        saveCategory={this.handleCategoryTextChange}
      />
    );
  };

  handleCategoryTextChange = (category) => {
    this.setState({
      categoryObject: category,
      errorMessage: "",
    });
  };

  handleCategoryRequest = (category) => {
    if (
      (category && category.category === "") ||
      (category && category.cat === "") ||
      category === null
    ) {
      this.setState({
        errorMessage: Strings("pleaseSelectRequiredFields"),
      });
    } else {
      this.toggleCategoryModal();
      category.id
        ? this.props.updateCategory(category)
        : this.props.addCategory(category);
    }
  };

  handleCategoryChange = (object, key, event) => {
    let value;
    if (key === "category") {
      value = Number(event.target.value);
    }
    this.setState({
      [object]: { ...this.state[object], [key]: value },
      errorMessage: "",
    });
  };

  render() {
    return (
      <div className='motorcycleCatalog ListContainer'>
        {this.state.editMotorcycleCatalogue &&
          this.renderMotorcycleCatalogModal()}
        {this.state.openCategoryModal && this.renderCategoryModal()}
        {this.renderSearchFilter()}
        <Row>
          <Col md={12} className=' errorText'>
            {this.props.errorMessage}
          </Col>
        </Row>
        {this.state.motorcycleData &&
          this.state.motorcycleData.length !== 0 ? (
          <Row className='cardAlign'>
            <Col md={12} className='w-100 pr-0'>
              <Row className='w-100'>
                {this.state.motorcycleData.map((product) => {
                  return (
                    <CustomCard
                      card={product}
                      key={product.id}
                      cardType='motorcycleCatalogue'
                      isMessenger={false}
                      handleCardClick={
                        this.onMotorcycleCatalogueClick
                      }
                    />
                  );
                })}
              </Row>
            </Col>
            <Col md={12} className='pt-3'>
              <Pagination
                activePage={this.state.activePage}
                recordsPerPage={this.state.recordsPerPage}
                numberOfPages={this.props.numberOfPages}
                onPageChange={this.onPageChange}
                dropup={true}
              />
            </Col>
          </Row>
        ) : (
          <div className='noRecordsFound'>
            {Strings("NoRecordsFound")}
          </div>
        )}
      </div>
    );
  }
}

export default MotorcycleCatalog;
